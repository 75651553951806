import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

export interface CookieConsentContextProps {
  finished?: boolean
  dialogOpen?: boolean
  types?: Record<string, boolean>
}

export const CookieConsentContext = createContext<
  | [
      CookieConsentContextProps,
      Dispatch<SetStateAction<CookieConsentContextProps>>,
    ]
  | null
>(null)

function createNoscript(config?: any) {
  if (typeof document === 'undefined') {
    return
  }

  const noscriptElement = document.createElement('noscript')

  if (config.name) {
    noscriptElement.dataset.name = config.name
  }

  if (config.content) {
    noscriptElement.innerHTML = config.content
  }

  document.body.appendChild(noscriptElement)
}

function createScript(config?: any) {
  if (typeof document === 'undefined') {
    return
  }

  const scriptElement = document.createElement('script')
  scriptElement.type = 'text/javascript'

  if (config.src) {
    scriptElement.src = config.src
  }

  if (config.name) {
    scriptElement.dataset.name = config.name
  }

  if (config.async) {
    scriptElement.async = config.async
  }

  if (config.content) {
    scriptElement.innerHTML = config.content
  }

  document.body.appendChild(scriptElement)
}

export function CookieConsentProvider({children}: PropsWithChildren<unknown>) {
  const [state, setState] = useState<CookieConsentContextProps>(() => ({
    finished: false,
    types: {tracking: false, performance: false},
    dialogOpen: false,
  }))
  const [performanceInitialized, setPerformanceInitialized] = useState(false)
  const [trackingInitialized, setTrackingInitialized] = useState(false)

  function initAnalytics() {
    if (document.querySelector('[data-name="google-analytics"]')) {
      return
    }

    createScript({
      src: 'https://www.googletagmanager.com/gtag/js?id=G-SGQF5M1T4G',
      async: true,
      name: 'google-analytics',
    })

    createScript({
      content: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-SGQF5M1T4G');`,
      name: 'google-analytics',
    })
  }

  function initHotjar() {
    if (document.querySelector('[data-name="hotjar"]')) {
      return
    }

    createScript({
      name: 'hotjar',
      content: `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:2617022,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
    })
  }

  function initLeadfeeder() {
    if (document.querySelector('[data-name="leadfeeder"]')) {
      return
    }

    createScript({
      name: 'leadfeeder',
      content: `(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('YEgkB8lqKMM7ep3Z');`,
    })
  }

  function initLinkedIn(partnerId: string, tagType: string) {
    const name = `linkedin-${tagType}`

    if (document.querySelector(`[data-name="${name}"]`)) {
      return
    }

    createScript({
      name: name,
      content: `_linkedin_partner_id = "${partnerId}";window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
    })

    createScript({
      name: name,
      content: `(function(l) {if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};window.lintrk.q=[]}var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})(window.lintrk);`,
    })

    createNoscript({
      name: name,
      content: `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=${partnerId}&fmt=gif" />`,
    })
  }


  async function initSentry() {
    const SENTRY_DSN =
      process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

    const Sentry = await import('@sentry/browser')

    Sentry.init({
      dsn:
        SENTRY_DSN ||
        'https://7deb6eb722864cc08cfce5c3c8f9bec4@o1015138.ingest.sentry.io/5980497',
      // Adjust this value in production, or use tracesSampler for greater control
      tracesSampleRate: 1.0,
      // ...
      // Note: if you want to override the automatic release value, do not set a
      // `release` value here - use the environment variable `SENTRY_RELEASE`, so
      // that it will also get attached to your source maps
    })
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const loadedConfig = window.localStorage.getItem('cookie-consent')

      if (loadedConfig) {
        const {finished, types} = JSON.parse(
          loadedConfig,
        ) as CookieConsentContextProps

        setState({
          finished,
          types,
        })
      }
    }
  }, [])

  useEffect(() => {
    if (state.finished && state.types?.tracking && !trackingInitialized) {
      initAnalytics()
      initHotjar()
      initLeadfeeder()
      initLinkedIn("3533082", "insight")
      initLinkedIn("5434457", "ad-conversion")


      setTrackingInitialized(true)
    }

    if (state.finished && state.types?.performance && !performanceInitialized) {
      initSentry()

      setPerformanceInitialized(true)
    }
  }, [performanceInitialized, state.finished, state.types, trackingInitialized])

  return (
    <CookieConsentContext.Provider value={[state, setState]}>
      {children}
    </CookieConsentContext.Provider>
  )
}

export default CookieConsentContext
