import dynamic from 'next/dynamic'
import {createContext, PropsWithChildren, useEffect, useState} from 'react'
import {AlertType} from '../../types'

export interface AlertContextProps {
  message?: string
  type?: AlertType
  setAlert: (message: string, type: AlertType) => void
  clearAlert: VoidFunction
}

export const AlertContext = createContext<AlertContextProps>({
  setAlert: () => {},
  clearAlert: () => {},
})

const Alert = dynamic(() => import('../../components/molecules/alert'), {
  ssr: false,
})

export function AlertProvider({children}: PropsWithChildren<unknown>) {
  const [message, setMessage] = useState<string>()
  const [type, setType] = useState<AlertType>()

  useEffect(() => {
    const timeout = setTimeout(clearAlert, 5000)

    return () => clearTimeout(timeout)
  }, [message])

  function setAlert(message: string, type: AlertType = 'success') {
    setMessage(message)
    setType(type)
  }

  function clearAlert() {
    setMessage(undefined)
    setType(undefined)
  }

  return (
    <AlertContext.Provider value={{message, type, setAlert, clearAlert}}>
      {message && <Alert type={type}>{message}</Alert>}
      {children}
    </AlertContext.Provider>
  )
}

export default AlertContext
