
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import '@fontsource/merriweather/700.css'
import '@fontsource/merriweather/900.css'
import '@fontsource/titillium-web/400.css'
import '@fontsource/titillium-web/600.css'
import type {AppProps} from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import {ErrorBoundary} from 'react-error-boundary'
import 'tailwindcss/tailwind.css'
import ActivityIndicator from '../components/atoms/activity-indicator'
import {AlertProvider} from '../contexts/alert-context'
import {CookieConsentProvider} from '../contexts/cookie-consent-context'
import {MenuProvider} from '../contexts/menu-context'
import '../styles/styles.css'

const CookieConsent = dynamic(
  () => import('../components/templates/cookie-consent'),
  {ssr: false},
)

const ProgressBar = dynamic(
  () => import('../components/dynamic/progress-bar'),
  {ssr: false, loading: () => <ActivityIndicator />},
)

const ErrorPage = dynamic(() => import('../components/dynamic/error-page'), {
  ssr: false,
  loading: () => <ActivityIndicator />,
})

const __Page_Next_Translate__ = function App({Component, pageProps}: AppProps) {
  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0c7597" />
        <meta name="msapplication-TileColor" content="#0c7597" />
        <meta name="theme-color" content="#0c7597" />
        <meta name="application-name" content="Bishop &amp; Co." />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Bishop &amp; Co." />
      </Head>

      <ProgressBar
        color="#4bbbc5"
        height={3}
        startPosition={0.3}
        options={{showSpinner: false}}
      />

      <ErrorBoundary
        fallbackRender={({error, resetErrorBoundary}) => (
          <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />
        )}
      >
        <CookieConsentProvider>
          <MenuProvider>
            <AlertProvider>
              <Component {...pageProps} />
            </AlertProvider>
          </MenuProvider>

          <CookieConsent />
        </CookieConsentProvider>
      </ErrorBoundary>
    </>
  )
}


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  