import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'

export const MenuContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>] | null
>(null)

export function MenuProvider({children}: PropsWithChildren<unknown>) {
  const menuState = useState(false)

  return (
    <MenuContext.Provider value={menuState}>{children}</MenuContext.Provider>
  )
}

export default MenuContext
