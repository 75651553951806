module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'careers', 'case-studies', 'services'],
    '/': ['home'],
    '/about-us': ['about-us'],
    '/careers': ['careers'],
    '/contact-us': ['contact-us'],
    '/contact-us/apply': ['contact-us'],
    '/case-studies': ['case-studies'],
    '/services': ['services'],
    '/health-tech': ['health-tech'],
    '/partners': ['partners'],
  },
}
